<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/image-depa.jpg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="headline font-weight-bold mb-3">
          ลงทะเบียนเข้าร่วมประชุม<br>สำหรับการประชุมวันที่ 19-20 กันยายน 2567
        </h1>
        

      </v-col>

      
    </v-row>
  </v-container>
  
  
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  name: 'CallbackThaid',
  data() {
    return { loading: false };
  },
  async mounted() {
    try {
      
      
      this.loading = true;
      let res = await axios.get('https://dmeetingapi.depa.or.th/memberjoin', {
        params: {
          code: this.$route.query.code
        }
      });
      if (res.data.responsecode === 200) {
        Swal.fire({
          type: "success",
          title: "ลงทะเบียนสำเร็จ",
          text: "วันที่ 19-20 กันยายน 2567",
          icon: "success",
          confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
        });
      } else if (res.data.responsecode === 400) {
        Swal.fire({
          title: "ลงทะเบียนไม่สำเร็จ",
          icon: "warning",
          confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
        });
      }
    } catch (e) {
      console.log(e);
    } 
  },
};
</script>
<style lang="scss" scoped></style>
