
<template>
  <div class="admin-view">
    <NavigationView />
    <v-container fluid>
    <v-flex>
      <v-card>
        <v-card-title>
          <span class="title"
            >Meeting
          </span>
          <v-spacer></v-spacer>
          <div>
            <!-- <v-btn   elevation="4" class="blue-grey mr-2" fab small dark @click.native.stop="updateSearchPanel">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn   elevation="4" class="brown lighten-1  mr-2" fab small dark @click.native="reloadData()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn   elevation="4" class="teal darken-2  mr-2" fab small dark @click.native="print()">
                <v-icon>mdi-printer</v-icon>
            </v-btn> -->
            <v-btn   elevation="4" class="deep-orange darken-3" fab small dark @click.native="add">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
         </div>
         <!-- <table-header-buttons :add="add" :reloadData="reloadData" :updateSearchPanel="updateSearchPanel"></table-header-buttons> -->
    
        </v-card-title>
        <Table v-if="loading === false" :headers="headers" :items="items" :pagination="pagination" @edit="edit" @remove="remove"></Table>
      </v-card>
    </v-flex>
    <!-- <search-panel :rightDrawer="rightDrawer" @cancelSearch="cancelSearch" @searchData="searchOrders">
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="reference" label="Reference" light v-model="searchFilter.contain.reference"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="customer" label="Customer" light v-model="searchFilter.contain.customer"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="minAmount"  type="number"  label="Min Amount" light v-model="searchFilter.greaterThanOrEqual.amount"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="maxAmount"  type="number"  label="Max Amount" light v-model="searchFilter.lessThanOrEqual.amount"></v-text-field>
        </v-flex>
      </v-layout> 
    </search-panel> -->
    <!-- <confirm-dialog
      :dialog="dialog"
      :dialogTitle="dialogTitle"
      :dialogText="dialogText"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    ></confirm-dialog>
    <v-snackbar v-if="loading === false" :right="true" :timeout="2000" :color="mode" v-model="snackbar">
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar> -->
  </v-container>
  <FooterView></FooterView>
  </div>
  
 
   
    
  </template>
  
  
  <script>
  import NavigationView from '../../components/Navigation.vue'
  import FooterView from '../../components/Footer.vue';
  import Table from '../../components/Table.vue';
  
//   import TableHeaderButtons from '../../components/TableHeaderButtons.vue'
//   import SearchPanel from '../../components/SearchPanel.vue';
//   import ConfirmDialog from '../../components/ConfirmDialog.vue';
  export default {
    name: 'ListMeetingView',
    components: {
      Table,
      NavigationView,
      FooterView

    //   ConfirmDialog,
    //   SearchPanel

    },
    methods: {
      add() {
         this.$router.push('CreateMeeting');
      }
    },
  }
  </script>
  
  <style scoped>
  .v-navigation-drawer {
    width: 200px;
  }
  
  .v-card-title {
    background-color: #4CAF50;
    color: white;
  }
  
  .v-card-subtitle {
    margin-bottom: 20px;
  }
  
  .v-text-field {
    margin-bottom: 20px;
  }
  </style>
  