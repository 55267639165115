<template>
  <v-app>
    <vue-progress-bar></vue-progress-bar>
     <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'App',

  data: () => ({
    mini: false,
  }),
};
</script>

