<template>
    <div>
      <v-card-text>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-data-table
        class="elevation-1"
        :search="search"
        :headers="headers"
        :items="items"
        :page.sync="pagination.page"
        :items-per-page="pagination.rowsPerPage"
        hide-default-footer
      >
        <template>
          <v-img 
            v-if="item.avatar !== null"
            small
            max-width="2em"
            :src="item.avatar"
            class="avatar "
            :srcset="item.avatar"
          />
        </template>
        <template>
          <v-icon v-if="item.membership === true">
            mdi-checkbox-marked-circle-outline
          </v-icon>
          <v-icon v-if="item.membership === false">
            mdi-close-circle-outline
          </v-icon>
        </template>
        <template>
          <v-btn
            elevation="4"
            fab
            class="teal mr-2"
            small
            dark
            @click.native="$emit('edit', item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            elevation="4"
            fab
            class="cyan"
            small
            @click.native="$emit('remove', item)"
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
        <template slot="no-data">
          <span>
            <p class="pt-2 blue--text subheading">
              <v-icon medium class="blue--text">mdi-info</v-icon>Sorry, nothing to
              display here :(
            </p>
          </span>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2" v-if="isNotEmpty">
        <v-pagination
          :length="pagination.pages"
          :total-visible="5"
          circle
        ></v-pagination>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'TableView',
  }
  </script>
  