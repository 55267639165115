<template>
    <v-app>
      <navigation />
  
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" md="12">
              <v-card>
                <v-card-title>
                    Term & Condition
                </v-card-title>
                <v-card-text>
                    <p>
                    สำนักงานส่งเสริมเศรษฐกิจดิจิทัล (depa) มีการดำเนินการจัดเก็บข้อมูลส่วนบุคคลสำหรับขอรับการช่วยเหลือหรืออุดหนุน
                    ภายใต้มาตรการช่วยเหลือหรือการอุดหนุนของสำนักงานส่งเสริมเศรษฐกิจดิจิทัล (depa) รวมไปถึงกิจกรรมต่างๆ ของสำนักงานฯ
                    ดังนั้น สำนักงานฯ จึงขอแจ้งให้สมาชิกทุกท่านทราบเกี่ยวกับวิธีการและวัตถุประสงค์ในการเก็บรวบรวม ใช้และเผยแพร่ข้อมูลส่วนบุคคล
                    โดยสิทธิ์ในข้อมูลส่วนบุคคลของผู้ใช้งานทุกท่าน จะอยู่ในความครอบครองของสำนักงานฯ และสำนักงานฯ จะประมวลผลข้อมูลส่วนบุคคล
                    ด้วยความโปร่งใสชัดเจน สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 สำนักงานฯ
                    จึงขอให้สมาชิกโปรดอ่านเพื่อทำความเข้าใจถึงการประมวลผล และการคุ้มครองข้อมูลส่วนบุคคล ของสำนักงานฯ
                    หากมีข้อสงสัยประการใดสามารถ ติดต่อสอบถามสำนักงานฯ ได้ตามรายละเอียดการติดต่อที่ปรากฏตามเอกสารฉบับนี้้
                    </p>
                    <h4>1. วัตถุประสงค์และการประมวลผลข้อมูล</h4>
                    <p>
                        การเก็บข้อมูลส่วนบุคคล : สมาชิกจำเป็นต้องให้ข้อมูลส่วนบุคคลเพื่อระบุและใช้ในการยืนยันตัวตน
                    </p>
                    <p>
                        การประมวลผลข้อมูล : สำนักงานฯ ดำเนินการประมวลผลข้อมูลส่วนบุคคลของสมาชิกภายใต้การประมวลผล ดังต่อไปนี้
                        (1) ความยินยอม (Consent) สำนักงานฯ จะขอความยินยอมจากสมาชิกก่อนนำข้อมูลส่วนบุคคลของสมาชิกไปในใช้ในการประมวลผล
                        เพื่อการยืนยันตัวตนและเชื่อมบัญชีสมาชิกภายใต้ข้อกำหนดการบริการของสำนักงานฯ
                        ในการนี้ สมาชิกมีความประสงค์จะเปลี่ยนแปลงความยินยอมสามารถเปลี่ยนแปลงได้ด้วยตัวสมาชิกเองในระบบสมาชิกของสำนักงานฯ
                        หรือติดต่อสำนักงานฯ และแจ้งความประสงค์ได้ตามรายละเอียดเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (ข้อ 5)

                        (2) อื่นๆ สำนักงานฯ อาจมีการนำข้อมูลส่วนบุคคลของสมาชิกไปใช้ในการประมวลผลเพื่อการจัดการ และการจัดทำรายงานของสำนักงานฯ
                        หรือปฏิบัติภารกิจอื่น ๆ โดยชอบด้วยกฎหมายได้ซึ่งเป็นไปตามมาตรา 24 แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                    </p>
                    <h4>2. ข้อมูลส่วนบุคคลที่จะทำการประมวลผลและระยะเวลาในการเก็บรวบรวมข้อมูล</h4>
                    <h5>ประเภทหรือรายการข้อมูลส่วนบุคคล</h5>
                    <p>
                        <ul>
                            <li>ข้อมูลที่ปรากฏตามหน้าบัตรประจำตัวประชาชน</li>
                            <li>ข้อมูลที่ได้รับจากการสอบถาม การให้ข้อคิดเห็น คำติชม คำถามของสมาชิก</li>
                        </ul>
                    </p>
                    <h5>ระยะเวลาในการเก็บรวบรวมข้อมูล</h5>
                    <p>
                        90 วัน นับถัดจากวันสิ้นสุดความเป็นสมาชิก depa ทั้งนี้ เมื่อพ้นกำหนดระยะเวลาข้างต้น สำนักงานฯ จะทำการลบ
                        หรือทำให้ข้อมูลส่วนบุคคลไม่สามารถระบุตัวบุคคลของสมาชิกได้ เว้นแต่มีเหตุจำเป็นที่ได้บัญญัติไว้เป็นสาระสำคัญตามกฎหมายอื่น
                    </p>
                    <h4>3. สิทธิของเจ้าของข้อมูล</h4>
                    <p>
                        (1) สิทธิในการเพิกถอนความยินยอมให้สำนักงานฯ ประมวลผลข้อมูลส่วนบุคคลของสมาชิก
                    </p>
                    <p>
                        (2) สิทธิในการเข้าถึงและรับสำเนาข้อมูลส่วนบุคคลที่สำนักงานฯ ได้เก็บรวบรวม ใช้ เปิดเผย
                    </p>
                    <p>
                        (3) สิทธิในการขอให้โอนย้ายข้อมูลส่วนบุคคลชองสมาชิก
                    </p>
                    <p>
                        (4) สิทธิในการคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผย ข้อมูลส่วนบุคคลของสมาชิก
                    </p>
                    <p>
                        (5) สิทธิในการขอให้ลบ ทำลาย หรือทำให้ข้อมูลส่วนบุคคลของสมาชิกเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูล ส่วนบุคคลนั้นได้
                    </p>
                    <p>
                        (6) สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลของสมาชิกให้ถูกต้องเป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                    </p>
                    <p>
                        (7) สิทธิในการที่จะร้องเรียนในกรณีที่สมาชิกเห็นว่าถูกสำนักงานฯ หรือเจ้าหน้าที่ หรือหน่วยร่วมดำเนินงานของสำนักงานฯ
                        ละเมิดสิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 การร้องขอใดๆ เพื่อการให้สิทธิของสมาชิกตามที่กล่าวข้างต้นจะต้องกระทำเป็นลายลักษณ์อักษร
                        และสำนักงานฯ จะดำเนินการภายในระยะเวลาตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 กำหนดที่เกี่ยวกับสิทธิของสมาชิกในฐานะเจ้าของข้อมูลส่วนบุคคล
                        ข้อจำกัดในการให้บริการกับสมาชิกในกรณีที่สมาชิกขอให้สำนักงานฯ ลบ ทำลายข้อมูล ระงับ ขอให้โอน การคัดค้าน หรือทำให้ข้อมูลไม่สามารถระบุตัวตนได้ หรือถอนความยินยอม
                        อาจจะทำให้เกิดข้อจำกัดกับการดำเนินในการให้บริการของสำนักงานฯ กับสมาชิกบางกรณีได้
                        ทั้งนี้ภายใต้ข้อกำหนดเงื่อนไขของการเป็นสมาชิกและตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วน บุคคล พ.ศ.2562 กำหนด
                    </p>
                    <h4>5. รายละเอียดเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h4>
                    <p>
                        หากมีเหตุร้องเรียนเกี่ยวกับข้อมูลส่วนบุคคลสามารถติดต่อประสานงานมายังเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลได้ในช่องทางดังนี้
                    </p>
                    <h5>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h5>
                    <p>
                        เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล<br />
                        เลขที่ 80 ถนนลาดพร้าว ซอยลาดพร้าว 4 แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900<br />
                        อีเมล์: doss@depa.or.th<br />
                        โทรศัพท์: 0-2026-2333<br />
                    </p>
                    <h5>ผู้คุ้มครองข้อมูลส่วนบุคคล</h5>
                    <p>
                        สำนักงานส่งเสริมเศรษฐกิจดิจิทัล (depa)<br />
                        เลขที่ 80 ถนนลาดพร้าว ซอยลาดพร้าว 4 แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900<br />
                        อีเมล์: doss@depa.or.th<br />
                        โทรศัพท์: 0-2026-2333<br />
                    </p>
                    <p>
                        หากสมาชิกเห็นว่าการประมวลผลข้อมูลส่วนบุคคลของสมาชิกไม่เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                        สมาชิกมีสิทธิที่จะร้องเรียนไปยังสำนักงานคณะกรรมการคุ้มครอง ข้อมูลส่วนบุคคล
                    </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
    </v-app>
  </template>
  
  
  <script>
  export default {
    name: 'TermCondition',
  }
  </script>
  
  <style scoped>
  .v-navigation-drawer {
    width: 200px;
  }
  
  .v-card-title {
    background-color: #4CAF50;
    color: white;
  }
  
  .v-card-subtitle {
    margin-bottom: 20px;
  }
  
  .v-text-field {
    margin-bottom: 20px;
  }
  </style>
  