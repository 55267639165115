<template>
  <div class="admin-view">
  <navigation-view></navigation-view>
    <v-container fluid>
    <v-flex xs12>
      <v-card class="grey lighten-4 elevation-0">
        <v-card-title class="title">
          สร้างการประชุม
          <v-spacer></v-spacer>
          <v-btn   elevation="4" fab small dark class="grey mr-2" @click.native="cancel()">
            <v-icon dark="">mdi-close-circle-outline</v-icon>
          </v-btn>
          <v-btn   elevation="4"  fab small dark class="purple mr-2" @click.native="save()">
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
          <!-- <v-btn   elevation="4" fab small dark class="blue" @click.native="addProduct()">
            <v-icon>mdi-plus</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text>
          <v-container fluid grid-list-md>
            <v-layout row wrap>
              <v-flex md12 xs12>
                <v-text-field
                  name="Title"
                  label="เรื่อง"
                  class="input-group--focused"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex md6 xs12>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="v-scale-transition"
                  v-model="StartDate"
                  offset-y
                  full-width
                  :nudge-left="40"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field lazy v-on="on" label="Start Date" v-model="meeting.StartDate"  prepend-icon="mdi-calendar" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="meeting.StartDate" no-title scrollable> </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex md6 xs12>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="v-scale-transition"
                  v-model="EndDate"
                  offset-y
                  full-width
                  :nudge-left="40"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field lazy v-on="on" label="End Date" v-model="meeting.EndDate"  prepend-icon="mdi-calendar" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="meeting.EndDate" no-title scrollable> </v-date-picker>
                </v-menu>
              </v-flex>
              
              
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-layout row justify-center>
      <v-dialog v-model="addProductModal" width="700" persistent>
        <v-card>
          <v-card-title>{{ modalTitle }}</v-card-title>
          <v-card-text>{{ modalText }}</v-card-text>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-select
                    required
                    v-bind:items="categories"
                    label="Category"
                    v-model="categoryId"
                    v-on:change="getProductsByCategory"
                  ></v-select>
                </v-flex>
                <v-flex md6 xs12>
                  <v-select required v-bind:items="products" label="Product" v-model="productId"></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn class="green--text darken-1" text="text" @click.native="saveProduct">Confirm</v-btn>

            <v-btn class="orange--text darken-1" text="text" @click.native="cancelAddProduct">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <confirm-dialog
      :dialog="dialog"
      :dialogTitle="dialogTitle"
      :dialogText="dialogText"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    ></confirm-dialog>
    <v-snackbar v-if="loading === false" :right="true" :timeout="2000" :color="mode" v-model="snackbar">
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar>
  </v-container>
  <footer-view></footer-view>
</div>
  </template>
  
  
  <script>
  import NavigationView from '../../components/Navigation.vue'
  import FooterView from '../../components/Footer.vue';
  import ConfirmDialog from '../../components/ConfirmDialog.vue';
  export default {
    name: 'CreateMeetingView',
    components: {
      ConfirmDialog,
      NavigationView,
      FooterView
    },
    data: () => ({
      StartDate: false,
      meeting: {
        StartDate: "",
        EndDate: ""
      },
    }),
    methods: {
      cancel() { 
         this.$router.push('Meeting');
      }
    },
    
  }
  </script>
  
  <style scoped>
  .v-navigation-drawer {
    width: 200px;
  }
  
  .v-card-title {
    background-color: #4CAF50;
    color: white;
  }
  
  .v-card-subtitle {
    margin-bottom: 20px;
  }
  
  .v-text-field {
    margin-bottom: 20px;
  }
  </style>
  