
<template>
  <v-navigation-drawer class="blue lighten-5" mini-variant-width="70" width="250" light :mini-variant.sync="mini" v-model="drawer" app>
      <v-list class="pa-0">
        <v-list-item tag="div">
          <v-list-item-action>
            <v-img max-width="2.5em" class="avatar"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>depa</v-list-item-title>
          </v-list-item-content>
          <!-- <v-spacer></v-spacer> -->
          <v-list-item-action style="max-width:1em">
            <v-menu bottom right offset-y origin="bottom right" transition="v-slide-y-transition">
              <template v-slot:activator="{ on }">
                <v-btn icon small light slot="activator" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="item in userMenus" :key="item.title" value="true" @click="handleUserActions(item)" router>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
          <v-list-item-action style="max-width:1em">
            <v-btn icon small @click.native.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item v-for="item in items" :key="item.title" @click="handleNavigtiion(item)" router>
          <v-list-item-action class="pr-1 pl-2 mr-1">
            <v-icon :class="activeMenuItem.includes(item.title) ? 'blue--text' : ''" :title="item.title">
              {{ item.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content :class="activeMenuItem.includes(item.title) ? 'blue--text' : ''">
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-app-bar app elevation=1>
      <v-app-bar-nav-icon @click.native.stop="drawer = !drawer" light></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="text-xs-center pr-3 mt-2">
        <v-badge left="">
          <span slot="badge">6</span>
          <v-icon large color="grey lighten-1">mdi-bell-outline</v-icon>
        </v-badge>

        <v-badge color="red">
          <span slot="badge">!</span>
          <v-icon large color="grey">mdi-email-outline</v-icon>
        </v-badge>
      </div>
    </v-app-bar> -->
</template>
<script>
export default {
  name: 'NavigationView',
}
</script>