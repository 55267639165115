import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ListMeetingView from '../views/Meetings/ListMeeting.vue'
import CreateMeetingView from '../views/Meetings/CreateMeeting.vue'
import CallbackThaid from '../views/CallbackThaid.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermCondition from '../views/TermCondition.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: ListMeetingView
  },
  {
    path: '/createmeeting',
    name: 'createmeeting',
    component: CreateMeetingView
  },
  {
    path: "/callback/thaid",
    name: "CallbackThaid",
    component: CallbackThaid,
  },
  {
    path: "/privacypolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/termcondition",
    name: "termCondition",
    component: TermCondition,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
